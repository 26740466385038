import DashboardController from '../dashboard/DashboardController.js';
import HomeController from '../home/HomeController.js';
import UserController from '../user/UserController.js';

// Routes
export const routes = [
  { path: '/', controller: HomeController },
  { path: '/dashboard', controller: DashboardController },
  { path: '/dashboard/property_new', controller: DashboardController },
  { path: '/login', controller: UserController },
  { path: '/properties', controller: DashboardController },
  { path: '/users', controller: DashboardController },
];
