// import icons from 'url:../../img/icons.svg'; // Parcel 2
class BannerCaptionView {
  render() {
    return `
      <div class="banner__caption">
        <h1 class="banner__title">The Best Way to Find Your Perfect Home</h1>
        <h3 class="banner__subtitle">
          With over 700,000 active listings, Realtyspace has the largest inventory of
          apartments in the United States.
        </h3>
        <span class="banner__btn">Get started</span>
        <div class="banner__arrow-circle">•</div>
        <svg class="banner__arrow-end js-arrow-end">
          <use xlink:href="#icon-arrow-end"></use>
        </svg>
        <div class="banner__arrow">
          <svg
            id="banner-line"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 774 284"
            enable-background="new 0 0 774 284"
            xml:space="preserve"
          >
            <path
              fill="none"
              stroke-width="2"
              stroke-miterlimit="10"
              stroke-dasharray="0,2004.009"
              d="M220.6,239.6
                                  c-3.6-15.5-17.5-27.1-34.1-27.1h-150c-19.3,0-35,15.7-35,35c0,19.3,15.7,35,35,35c0,0,88,0,150,0c169,0,244.9-7.5,291-19
                                  c41.3-10.2,114.1-33.7,118-83c4.2-53.5-59.4-67.5-102-54c-47.2,15-52.3,78.2,1,90c58.1,12.9,169.6-53.6,274.7-210"
            />
          </svg>
        </div>
      </div>
    `;
  }
}

export default new BannerCaptionView();
