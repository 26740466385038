// import icons from 'url:../../img/icons.svg'; // Parcel 2

class BannerSearchFormView {
  render() {
    return `
      <div class="banner__search">
        <h4 class="banner__sidebar-title">The Best Way to Find Your Perfect Home</h4>
        <!-- BEGIN SEARCH-->
        <form
          action="properties_listing_list.html"
          class="form form--flex form--search js-search-form form--banner-sidebar"
        >
          <div class="row">
            <div class="form-group">
              <label for="in-keyword" class="control-label">Keyword</label>
              <input
                type="text"
                id="in-keyword"
                placeholder="Text"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <label for="in-contract-type" class="control-label">Listing Type</label>
              <select
                id="in-contract-type"
                data-placeholder="Contract type"
                class="form-control"
              >
                <option label=" "></option>
                <option>Sale</option>
                <option>Rent</option>
              </select>
            </div>
            <div class="form-group">
              <span class="control-label">Property type</span>
              <div class="dropdown dropdown--select">
                <button
                  type="button"
                  data-toggle="dropdown"
                  data-placeholder="Property type"
                  class="dropdown-toggle js-select-checkboxes-btn"
                >
                  Property type
                </button>
                <div class="dropdown-menu js-dropdown-menu js-select-checkboxes">
                  <ul>
                    <li>
                      <input
                        id="checkbox_type_1"
                        type="checkbox"
                        name="checkbox_type_1"
                        class="in-checkbox"
                      />
                      <label
                        for="checkbox_type_1"
                        data-toggle="tooltip"
                        data-placement="left"
                        title="Tooltip on top"
                        class="in-label"
                        >Privat apartment</label
                      >
                    </li>
                  </ul>
                  <!-- end of block .dropdown-menu-->
                </div>
              </div>
            </div>
            <div class="form-group">
              <span class="control-label">Location level 1</span>
              <div class="dropdown dropdown--select">
                <button
                  type="button"
                  data-toggle="dropdown"
                  data-placeholder="States"
                  class="dropdown-toggle js-select-checkboxes-btn"
                >
                  States
                </button>
                <div class="dropdown-menu js-dropdown-menu js-select-checkboxes">
                  <div class="region-select">
                    <ul class="js-checkboxes-tree bonsai region-select__list">
                      <li>
                        <input
                          type="checkbox"
                          name="location[]"
                          value="Fresno"
                          id="region-select-states-0"
                          class="in-checkbox"
                        />
                        <label
                          for="region-select-states-0"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Fresno"
                          class="in-label"
                          >Fresno</label
                        >
                        <ul>
                          <li>
                            <input
                              type="checkbox"
                              name="location[]"
                              value="Clovis"
                              id="region-select-states-1"
                              class="in-checkbox"
                            />
                            <label
                              for="region-select-states-1"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Clovis"
                              class="in-label"
                              >Clovis</label
                            >
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              name="location[]"
                              value="Fresno"
                              id="region-select-states-2"
                              class="in-checkbox"
                            />
                            <label
                              for="region-select-states-2"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Fresno"
                              class="in-label"
                              >Fresno</label
                            >
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <div class="region-select__buttons">
                      <button
                        type="button"
                        class="region-select__btn region-select__btn--reset js-select-checkboxes-reset"
                      >
                        Clear
                      </button>
                      <button
                        type="button"
                        class="region-select__btn js-select-checkboxes-accept"
                      >
                        Accept
                      </button>
                    </div>
                  </div>
                  <!-- end of block .region-select-->
                </div>
                <!-- end of block .dropdown-menu-->
              </div>
            </div>
            <div class="form-group">
              <label for="in-datetime" class="control-label">Date Range</label>
              <input
                type="text"
                id="in-datetime"
                data-start-date="12/03/2015"
                data-end-date="12/22/2015"
                data-time-picker="true"
                data-single-picker="false"
                class="js-datetimerange form-control"
              />
            </div>
            <div class="form-group">
              <div class="form__mode">
                <button
                  type="button"
                  data-mode="input"
                  class="form__mode-btn js-input-mode"
                >
                  Input
                </button>
              </div>
              <label for="range_price" class="control-label">Price</label>
              <div class="form__ranges">
                <input id="range_price" class="js-search-range form__ranges-in" />
              </div>
              <div class="form__inputs js-search-inputs">
                <input
                  type="text"
                  id="in-price-from"
                  placeholder="From"
                  data-input-type="from"
                  class="form-control js-field-range"
                />
                <input
                  type="text"
                  id="in-price-to"
                  placeholder="To"
                  data-input-type="to"
                  class="form-control js-field-range"
                />
              </div>
            </div>
            <div class="form-group">
              <div class="form__mode">
                <button
                  type="button"
                  data-mode="input"
                  class="form__mode-btn js-input-mode"
                >
                  Input
                </button>
              </div>
              <label for="range_area" class="control-label">Area</label>
              <div class="form__ranges">
                <input id="range_area" class="js-search-range form__ranges-in" />
              </div>
              <div class="form__inputs js-search-inputs">
                <input
                  type="text"
                  id="in-area-from"
                  placeholder="From"
                  data-input-type="from"
                  class="form-control js-field-range"
                />
                <input
                  type="text"
                  id="in-area-to"
                  placeholder="To"
                  data-input-type="to"
                  class="form-control js-field-range"
                />
              </div>
            </div>
            <div class="form__buttons">
              <button type="submit" class="form__submit">Search</button>
            </div>
          </div>
        </form>
        <!-- end of block-->
        <!-- END SEARCH-->
      </div>
    `;
  }
}

export default new BannerSearchFormView();
