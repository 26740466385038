import PageView from '../../core/PageView.js';
import BannerSearchFormView from './BannerSearchFormView.js';
import BannerCaptionView from './BannerCaptionView.js';

class BannerView extends PageView {
  _pageElement = document.querySelector('.site-wrap .content');
  _page = 'Home';

  _generateMarkup() {
    return `
      <div class="widget js-widget">
        <div class="widget__content">
          <div class="banner js-banner banner--wide">
            <div class="banner__item">
              <div class="map map--index map--banner">
                <div class="map__buttons">
                  <button type="button" class="map__change-map js-map-btn">
                    Property Map
                  </button>
                </div>
                <div class="map__wrap">
                  <div
                    data-infobox-theme="white"
                    class="map__view js-map-index-canvas"
                  ></div>
                </div>
              </div>
              <div class="container">
                <div class="row">
                  ${BannerCaptionView.render()}
                  ${BannerSearchFormView.render()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    `;
  }
}
export default new BannerView();
