import { router } from './core/router.js';
import { HeaderComponent } from './common/header.js';
import { NaviComponent } from './common/navi.js';
import { FooterComponent } from './common/footer.js';

import 'regenerator-runtime/runtime';

const initApp = function () {
  //Rendering common component
  commonCompRender();

  //Setting up starting event
  commonEventSetter();
};

/************************************
 * Render common Components
 */
const commonCompRender = () => {
  // Header;
  HeaderComponent.render();
  //Navi
  NaviComponent.render();
  //Footer
  FooterComponent.render();
};

/************************************
 * Setting Common Events(Routers etc)
 */
const commonEventSetter = () => {
  window.addEventListener('hashchange', router);
  window.addEventListener('load', router);
};

//Initliazing app
initApp();

// const getAgentData = async function () {
//   const axios = require('axios');

//   // Make a request for a user with a given ID
//   axios
//     .get('http://localhost:8765/agent/dashboard.json', {
//       onDownloadProgress: progressEvent => {
//         console.log(progressEvent);
//         // let percentCompleted = Math.round(
//         //   (progressEvent.loaded * 100) / progressEvent.total
//         // );
//         // console.log(progressEvent.lengthComputable);
//         // console.log(percentCompleted);
//       },
//     })
//     .then(function (response) {
//       // handle success
//       console.log(response);
//     })
//     .catch(function (error) {
//       // handle error
//       console.log(error);
//     })
//     .then(function () {
//       // always executed
//     });
//   // try {
//   //   const url = 'http://localhost:8765/agent/dashboard.json';
//   //   const response = await fetch(url);
//   //   console.log;
//   //   // const reader = response.body.getReader();
//   //   // Step 2: get total length
//   //   // const contentLength = +response.headers.get('Content-Length');
//   //   // console.log(contentLength);
//   //   const agentData = await response.json();
//   //   console.log(agentData);
//   // } catch (error) {
//   //   alert(error);
//   // }
// };
