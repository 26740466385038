export const ErrorComponent = {
  renderHtml: function (error) {
    console.log(`errrorr`);
    document.querySelector('.site-wrap .content').innerHTML = `Error`;
  },

  render: function (error) {
    //TODO: Set default style on top
    //TODO: Check error type
    //TODO: Render the 404 html
    this.renderHtml();
    // console.log(error);
  },
};
