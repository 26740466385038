import icons from '../../img/icons.svg';

export const NaviComponent = {
  renderHtml: function () {
    let naviHtml = `<div class="navbar__row js-navbar-row"><a href="/" class="navbar__brand">
              <svg class="navbar__brand-logo">
                <use xlink:href="${icons}#icon-logo"></use>
              </svg></a>
            
          <div id="navbar-collapse-1" class="navbar__wrap">
              <ul class="navbar__nav">
                <li class="navbar__item js-dropdown"><a class="navbar__link">Home
                    <svg class="navbar__arrow">
                      <use xlink:href="${icons}#icon-arrow-right"></use>
                    </svg></a>
                  <div role="menu" class="js-dropdown-menu navbar__dropdown navbar__dropdown--colls-1">
                    <button class="navbar__back js-navbar-submenu-back">
                      <svg class="navbar__arrow">
                        <use xlink:href="${icons}#icon-arrow-left"></use>
                      </svg>Back
                    </button>
                    <div class="navbar__submenu">
                      <ul class="navbar__subnav">
                        <li class="navbar__subitem"><a href="/" class="navbar__sublink js-navbar-sublink">Banner &amp; Search</a></li>
                        <li class="navbar__subitem"><a href="index_slider.html" class="navbar__sublink js-navbar-sublink">Property slider</a></li>
                        <li class="navbar__subitem"><a href="index_projects.html" class="navbar__sublink js-navbar-sublink">Property projects</a></li>
                        <li class="navbar__subitem"><a href="index_slider_search.html" class="navbar__sublink js-navbar-sublink">Slider &amp; Search</a></li>
                        <li class="navbar__subitem"><a href="index_slider_auth.html" class="navbar__sublink js-navbar-sublink">Slider &amp; Authorization</a></li>
                        <li class="navbar__subitem"><a href="index_vmap_light.html" class="navbar__sublink js-navbar-sublink">Google Map &amp; Light search</a></li>
                        <li class="navbar__subitem"><a href="index_vmap_dark.html" class="navbar__sublink js-navbar-sublink">Google Map &amp; Dark search</a></li>
                        <li class="navbar__subitem"><a href="index_hmap_light.html" class="navbar__sublink js-navbar-sublink">Google Map &amp; Horizontal search</a></li>
                        <li class="navbar__subitem"><a href="feature_map_leaflet.html" class="navbar__sublink js-navbar-sublink">Openstreet Map &amp; Filter</a></li>
                        <li class="navbar__subitem"><a href="feature_vmap_fullscreen.html" class="navbar__sublink js-navbar-sublink">Fullscreen Google Map</a></li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li class="navbar__item js-dropdown"><a class="navbar__link">Properties
                    <svg class="navbar__arrow">
                      <use xlink:href="${icons}#icon-arrow-right"></use>
                    </svg></a>
                  <div role="menu" class="js-dropdown-menu navbar__dropdown navbar__dropdown--colls-2">
                    <button class="navbar__back js-navbar-submenu-back">
                      <svg class="navbar__arrow">
                        <use xlink:href="${icons}#icon-arrow-left"></use>
                      </svg>Back
                    </button>
                    <div class="navbar__submenu">
                      <h5 class="navbar__subtitle">Details</h5>
                      <ul class="navbar__subnav">
                        <li class="navbar__subitem"><a href="property_details.html" class="navbar__sublink js-navbar-sublink">Property</a></li>
                        <li class="navbar__subitem"><a href="property_details_projected.html" class="navbar__sublink js-navbar-sublink">Property projected</a></li>
                        <li class="navbar__subitem"><a href="property_details_agent.html" class="navbar__sublink js-navbar-sublink">Property &amp; agent</a></li>
                      </ul>
                    </div>
                    <div class="navbar__submenu">
                      <h5 class="navbar__subtitle">Listing</h5>
                      <ul class="navbar__subnav">
                        <li class="navbar__subitem navbar__subitem-dropdown js-dropdown"><a class="navbar__sublink js-navbar-sublink">Grid
                            <svg class="navbar__arrow">
                              <use xlink:href="${icons}#icon-arrow-right"></use>
                            </svg></a>
                          <div class="navbar__submenu navbar__submenu--level">
                            <button class="navbar__back js-navbar-submenu-back">
                              <svg class="navbar__arrow">
                                <use xlink:href="${icons}#icon-arrow-left"></use>
                              </svg>Back
                            </button>
                            <ul class="navbar__subnav">
                              <li class="navbar__subitem"><a href="feature_grid_large.html" class="navbar__sublink js-navbar-sub-sublink">Large</a></li>
                              <li class="navbar__subitem"><a href="properties_listing_grid.html" class="navbar__sublink js-navbar-sub-sublink">Medium</a></li>
                              <li class="navbar__subitem"><a href="feature_grid_small.html" class="navbar__sublink js-navbar-sub-sublink">Small</a></li>
                            </ul>
                          </div>
                        </li>
                        <li class="navbar__subitem"><a href="properties_listing_list.html" class="navbar__sublink js-navbar-sublink">List</a></li>
                        <li class="navbar__subitem"><a href="properties_listing_table.html" class="navbar__sublink js-navbar-sublink">Table</a></li>
                        <li class="navbar__subitem"><a href="properties_listing_empty.html" class="navbar__sublink js-navbar-sublink">Empty</a></li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li class="navbar__item js-dropdown"><a class="navbar__link">Agents
                    <svg class="navbar__arrow">
                      <use xlink:href="${icons}#icon-arrow-right"></use>
                    </svg></a>
                  <div role="menu" class="js-dropdown-menu navbar__dropdown">
                    <button class="navbar__back js-navbar-submenu-back">
                      <svg class="navbar__arrow">
                        <use xlink:href="${icons}#icon-arrow-left"></use>
                      </svg>Back
                    </button>
                    <div class="navbar__submenu">
                      <ul class="navbar__subnav">
                        <li class="navbar__subitem"><a href="agent_profile.html" class="navbar__sublink js-navbar-sub-sublink">Agent's profile</a></li>
                        <li class="navbar__subitem navbar__subitem-dropdown js-dropdown"><a href="agents_listing_list.html" class="navbar__sublink js-navbar-sublink">Agent's listing
                            <svg class="navbar__arrow">
                              <use xlink:href="${icons}#icon-arrow-right"></use>
                            </svg></a>
                          <div class="navbar__submenu navbar__submenu--level">
                            <button class="navbar__back js-navbar-submenu-back">
                              <svg class="navbar__arrow">
                                <use xlink:href="${icons}#icon-arrow-left"></use>
                              </svg>Back
                            </button>
                            <ul class="navbar__subnav">
                              <li class="navbar__subitem"><a href="agents_listing_list.html" class="navbar__sublink js-navbar-sub-sublink">List</a></li>
                              <li class="navbar__subitem"><a href="agents_listing_grid.html" class="navbar__sublink js-navbar-sub-sublink">Grid</a></li>
                            </ul>
                          </div>
                        </li>
                        <li class="navbar__subitem navbar__subitem-dropdown js-dropdown"><a href="agent_profile_blog_list.html" class="navbar__sublink js-navbar-sublink">Agent's blog
                            <svg class="navbar__arrow">
                              <use xlink:href="${icons}#icon-arrow-right"></use>
                            </svg></a>
                          <div class="navbar__submenu navbar__submenu--level">
                            <button class="navbar__back js-navbar-submenu-back">
                              <svg class="navbar__arrow">
                                <use xlink:href="${icons}#icon-arrow-left"></use>
                              </svg>Back
                            </button>
                            <ul class="navbar__subnav">
                              <li class="navbar__subitem"><a href="agent_profile_blog_list.html" class="navbar__sublink js-navbar-sub-sublink">List</a></li>
                              <li class="navbar__subitem"><a href="agent_profile_blog_grid.html" class="navbar__sublink js-navbar-sub-sublink">Grid</a></li>
                              <li class="navbar__subitem"><a href="agent_profile_blog_details.html" class="navbar__sublink js-navbar-sub-sublink">Details</a></li>
                            </ul>
                          </div>
                        </li>
                        <li class="navbar__subitem navbar__subitem-dropdown js-dropdown"><a href="agent_profile_listing_list.html" class="navbar__sublink js-navbar-sublink">Agent's properties
                            <svg class="navbar__arrow">
                              <use xlink:href="${icons}#icon-arrow-right"></use>
                            </svg></a>
                          <div class="navbar__submenu navbar__submenu--level">
                            <button class="navbar__back js-navbar-submenu-back">
                              <svg class="navbar__arrow">
                                <use xlink:href="${icons}#icon-arrow-left"></use>
                              </svg>Back
                            </button>
                            <ul class="navbar__subnav">
                              <li class="navbar__subitem"><a href="agent_profile_listing_list.html" class="navbar__sublink js-navbar-sub-sublink">List</a></li>
                              <li class="navbar__subitem"><a href="agent_profile_listing_grid.html" class="navbar__sublink js-navbar-sub-sublink">Grid</a></li>
                              <li class="navbar__subitem"><a href="agent_profile_listing_table.html" class="navbar__sublink js-navbar-sub-sublink">Table</a></li>
                            </ul>
                          </div>
                        </li>
                        <li class="navbar__subitem"><a href="agent_profile_testimonials.html" class="navbar__sublink js-navbar-sub-sublink">Agent's testimonials</a></li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li class="navbar__item js-dropdown"><a class="navbar__link">User
                    <svg class="navbar__arrow">
                      <use xlink:href="${icons}#icon-arrow-right"></use>
                    </svg></a>
                  <div role="menu" class="js-dropdown-menu navbar__dropdown navbar__dropdown--colls-2">
                    <button class="navbar__back js-navbar-submenu-back">
                      <svg class="navbar__arrow">
                        <use xlink:href="${icons}#icon-arrow-left"></use>
                      </svg>Back
                    </button>
                    <div class="navbar__submenu">
                      <h5 class="navbar__subtitle">Pages</h5>
                      <ul class="navbar__subnav">
                        <li class="navbar__subitem"><a href="my_listings.html" class="navbar__sublink js-navbar-sublink">My listings</a></li>
                        <li class="navbar__subitem"><a href="my_listings_add_edit.html" class="navbar__sublink js-navbar-sublink">Property submit</a></li>
                        <li class="navbar__subitem"><a href="my_profile.html" class="navbar__sublink js-navbar-sublink">Profile</a></li>
                      </ul>
                    </div>
                    <div class="navbar__submenu">
                      <h5 class="navbar__subtitle">Auth</h5>
                      <ul class="navbar__subnav">
                        <li class="navbar__subitem"><a href="user_login.html" class="navbar__sublink js-navbar-sublink">Login</a></li>
                        <li class="navbar__subitem"><a href="user_register.html" class="navbar__sublink js-navbar-sublink">Register</a></li>
                        <li class="navbar__subitem"><a href="user_restore_pass.html" class="navbar__sublink js-navbar-sublink">Restore</a></li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li class="navbar__item js-dropdown"><a class="navbar__link">Blog
                    <svg class="navbar__arrow">
                      <use xlink:href="${icons}#icon-arrow-right"></use>
                    </svg></a>
                  <div role="menu" class="js-dropdown-menu navbar__dropdown navbar__dropdown--colls-1">
                    <button class="navbar__back js-navbar-submenu-back">
                      <svg class="navbar__arrow">
                        <use xlink:href="${icons}#icon-arrow-left"></use>
                      </svg>Back
                    </button>
                    <div class="navbar__submenu">
                      <ul class="navbar__subnav">
                        <li class="navbar__subitem"><a href="blog.html" class="navbar__sublink js-navbar-sublink">Blog list</a></li>
                        <li class="navbar__subitem"><a href="blog_details.html" class="navbar__sublink js-navbar-sublink">Blog details</a></li>
                        <li class="navbar__subitem"><a href="blog_empty.html" class="navbar__sublink js-navbar-sublink">Blog empty</a></li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li class="navbar__item js-dropdown"><a class="navbar__link">Pages
                    <svg class="navbar__arrow">
                      <use xlink:href="${icons}#icon-arrow-right"></use>
                    </svg></a>
                  <div role="menu" class="js-dropdown-menu navbar__dropdown navbar__dropdown--colls-1">
                    <button class="navbar__back js-navbar-submenu-back">
                      <svg class="navbar__arrow">
                        <use xlink:href="${icons}#icon-arrow-left"></use>
                      </svg>Back
                    </button>
                    <div class="navbar__submenu">
                      <ul class="navbar__subnav">
                        <li class="navbar__subitem"><a href="page.html" class="navbar__sublink js-navbar-sublink">Сontent page</a></li>
                        <li class="navbar__subitem"><a href="faq.html" class="navbar__sublink js-navbar-sublink">Faq</a></li>
                        <li class="navbar__subitem"><a href="reviews.html" class="navbar__sublink js-navbar-sublink">Testimonials</a></li>
                        <li class="navbar__subitem"><a href="pricing.html" class="navbar__sublink js-navbar-sublink">Pricing</a></li>
                        <li class="navbar__subitem"><a href="gallery.html" class="navbar__sublink js-navbar-sublink">Gallery</a></li>
                        <li class="navbar__subitem"><a href="email.html" class="navbar__sublink js-navbar-sublink">Email template</a></li>
                        <li class="navbar__subitem navbar__subitem-dropdown js-dropdown"><a class="navbar__sublink js-navbar-sublink">Errors
                            <svg class="navbar__arrow">
                              <use xlink:href="${icons}#icon-arrow-right"></use>
                            </svg></a>
                          <div class="navbar__submenu navbar__submenu--level">
                            <button class="navbar__back js-navbar-submenu-back">
                              <svg class="navbar__arrow">
                                <use xlink:href="${icons}#icon-arrow-left"></use>
                              </svg>Back
                            </button>
                            <ul class="navbar__subnav">
                              <li class="navbar__subitem"><a href="error_403.html" class="navbar__sublink js-navbar-sub-sublink">403 Error</a></li>
                              <li class="navbar__subitem"><a href="error_404.html" class="navbar__sublink js-navbar-sub-sublink">404 Error</a></li>
                              <li class="navbar__subitem"><a href="error_500.html" class="navbar__sublink js-navbar-sub-sublink">500 Error</a></li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li class="navbar__item"><a href="contacts.html" class="navbar__link">Contacts</a></li>
                <li class="navbar__item js-dropdown"><a class="navbar__link">Dashboard
                    <svg class="navbar__arrow">
                      <use xlink:href="${icons}#icon-arrow-right"></use>
                    </svg></a>
                  <div role="menu" class="js-dropdown-menu navbar__dropdown navbar__dropdown--colls-1 navbar__dropdown--right">
                    <button class="navbar__back js-navbar-submenu-back">
                      <svg class="navbar__arrow">
                        <use xlink:href="${icons}#icon-arrow-left"></use>
                      </svg>Back
                    </button>
                    <div class="navbar__submenu">
                      <ul class="navbar__subnav">
                        <li class="navbar__subitem active"><a href="/dashboard" class="navbar__sublink">Dashboard</a></li>
                        <li class="navbar__subitem"><a href="dashboard_property_new.html" class="navbar__sublink">Add Listing</a></li>
                        <li class="navbar__subitem"><a href="dashboard_news.html" class="navbar__sublink">News Feed</a></li>
                        <li class="navbar__subitem"><a href="dashboard_profile.html" class="navbar__sublink">Profile</a></li>
                        <li class="navbar__subitem"><a href="dashboard_activity.html" class="navbar__sublink">Activity Log</a></li>
                        <li class="navbar__subitem"><a href="dashboard_favorites_listings.html" class="navbar__sublink">Favorite Listings</a></li>
                        <li class="navbar__subitem"><a href="dashboard_favorites_agents.html" class="navbar__sublink">Favorite Agents</a></li>
                        <li class="navbar__subitem"><a href="dashboard_favorites_search.html" class="navbar__sublink">Favorite search</a></li>
                        <li class="navbar__subitem"><a href="dashboard_financials.html" class="navbar__sublink">Financials</a></li>
                        <li class="navbar__subitem"><a href="dashboard_statistics.html" class="navbar__sublink">Statistics</a></li>
                        <li class="navbar__subitem"><a href="dashboard_property.html" class="navbar__sublink">Property management</a></li>
                        <li class="navbar__subitem"><a href="dashboard_blog.html" class="navbar__sublink">Blog Management</a></li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li class="navbar__item js-dropdown"><a class="navbar__link">UI
                    <svg class="navbar__arrow">
                      <use xlink:href="${icons}#icon-arrow-right"></use>
                    </svg></a>
                  <div role="menu" class="js-dropdown-menu navbar__dropdown navbar__dropdown--colls-2 navbar__dropdown--right">
                    <button class="navbar__back js-navbar-submenu-back">
                      <svg class="navbar__arrow">
                        <use xlink:href="${icons}#icon-arrow-left"></use>
                      </svg>Back
                    </button>
                    <div class="navbar__submenu">
                      <h5 class="navbar__subtitle">Variations</h5>
                      <ul class="navbar__subnav">
                        <li class="navbar__subitem"><a href="index_not_available.html" class="navbar__sublink js-navbar-sublink">Feature not available</a></li>
                        <li class="navbar__subitem"><a href="feature_boxed.html" class="navbar__sublink js-navbar-sublink">Layout boxed</a></li>
                        <li class="navbar__subitem"><a href="feature_left_sidebar.html" class="navbar__sublink js-navbar-sublink">Sidebar left</a></li>
                      </ul>
                    </div>
                    <div class="navbar__submenu">
                      <h5 class="navbar__subtitle">Elements</h5>
                      <ul class="navbar__subnav">
                        <li class="navbar__subitem"><a href="feature_ui.html" class="navbar__sublink js-navbar-sublink">UI</a></li>
                        <li class="navbar__subitem"><a href="feature_typography.html" class="navbar__sublink js-navbar-sublink">Typography</a></li>
                        <li class="navbar__subitem"><a href="feature_loaders.html" class="navbar__sublink js-navbar-sublink">Feature loaders</a></li>
                        <li class="navbar__subitem"><a href="bootstrap.html" class="navbar__sublink js-navbar-sublink">Twitter Bootstrap</a></li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li class="navbar__item navbar__item--mob js-dropdown" style=""><a class="navbar__link">Language
                    <svg class="navbar__arrow">
                      <use xlink:href="${icons}#icon-arrow-right"></use>
                    </svg></a>
                  <div role="menu" class="js-dropdown-menu navbar__dropdown navbar__dropdown--right">
                    <button class="navbar__back js-navbar-submenu-back">
                      <svg class="navbar__arrow">
                        <use xlink:href="${icons}#icon-arrow-left"></use>
                      </svg>Back
                    </button>
                    <div class="navbar__submenu">
                      <ul class="navbar__subnav">
                        <li class="navbar__subitem active"><a href="" class="navbar__sublink js-navbar-sublink">English</a></li>
                        <li class="navbar__subitem"><a href="" class="navbar__sublink js-navbar-sublink">Francais</a></li>
                        <li class="navbar__subitem"><a href="" class="navbar__sublink js-navbar-sublink">Italian</a></li>
                        <li class="navbar__subitem"><a href="" class="navbar__sublink js-navbar-sublink">Russian</a></li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li class="navbar__item navbar__item--mob js-dropdown" style=""><a class="navbar__link">Currency
                    <svg class="navbar__arrow">
                      <use xlink:href="${icons}#icon-arrow-right"></use>
                    </svg></a>
                  <div role="menu" class="js-dropdown-menu navbar__dropdown navbar__dropdown--right">
                    <button class="navbar__back js-navbar-submenu-back">
                      <svg class="navbar__arrow">
                        <use xlink:href="${icons}#icon-arrow-left"></use>
                      </svg>Back
                    </button>
                    <div class="navbar__submenu">
                      <ul class="navbar__subnav">
                        <li class="navbar__subitem active"><a href="" class="navbar__sublink js-navbar-sublink">USD</a></li>
                        <li class="navbar__subitem"><a href="" class="navbar__sublink js-navbar-sublink">EURO</a></li>
                        <li class="navbar__subitem"><a href="" class="navbar__sublink js-navbar-sublink">RUB</a></li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li class="navbar__item navbar__item--mob js-dropdown" style=""><a class="navbar__link">Measures
                    <svg class="navbar__arrow">
                      <use xlink:href="${icons}#icon-arrow-right"></use>
                    </svg></a>
                  <div role="menu" class="js-dropdown-menu navbar__dropdown navbar__dropdown--right">
                    <button class="navbar__back js-navbar-submenu-back">
                      <svg class="navbar__arrow">
                        <use xlink:href="${icons}#icon-arrow-left"></use>
                      </svg>Back
                    </button>
                    <div class="navbar__submenu">
                      <div class="switch switch--menu">
                        <label>M <sup>2</sup>
                          <input type="checkbox" checked=""><span class="lever"></span>Sq Ft
                        </label>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <!-- end of block  navbar__nav-->
            </div></div>`;
    document.querySelector('nav .container').innerHTML = naviHtml;
  },
  render: function () {
    this.renderHtml();
  },
};
