// import icons from 'url:../../../img/icons.svg'; // Parcel 2
// import icons from '../../../img/icons.svg'; // Parcel 2
import PageView from '../../core/PageView.js';

class LoginView extends PageView {
  _pageElement = document.querySelector('.site-wrap');
  _page = 'User';

  _generateMarkup() {
    return `
      <!-- BEGIN BREADCRUMBS-->
      <nav class="breadcrumbs">
        <div class="container">
          <ul>
            <li class="breadcrumbs__item">
              <a href="" class="breadcrumbs__link">Home</a>
            </li>
            <li class="breadcrumbs__item">
              <a href="" class="breadcrumbs__link">Login</a>
            </li>
          </ul>
        </div>
      </nav>
      <!-- END BREADCRUMBS-->
      <div class="center">
        <div class="container">
          <div class="row">
            <section class="site">
              <header class="site__header">
                <h1 class="site__title">Login</h1>
                <h2 class="site__headline">Enter your login and password</h2>
              </header>
              <div class="site__main">
                <div class="widget js-widget widget--main widget--no-border">
                  <div class="widget__content">
                    <div class="auth auth--inline">
                      <div class="auth__wrap auth__wrap--login">
                        <!-- BEGIN AUTH LOGIN-->
                        <h5 class="auth__title">Login in your account</h5>
                        <form
                          action="#"
                          class="form form--flex form--auth js-login-form js-parsley"
                        >
                          <div class="row">
                            <div class="form-group">
                              <label for="login-username-inline" class="control-label"
                                >Username</label
                              >
                              <input
                                type="text"
                                name="username"
                                id="login-username-inline"
                                required
                                data-parsley-trigger="keyup"
                                data-parsley-minlength="6"
                                data-parsley-validation-threshold="5"
                                data-parsley-minlength-message="Login should be at least 6 chars"
                                class="form-control"
                              />
                            </div>
                            <div class="form-group">
                              <label for="login-password-inline" class="control-label"
                                >Password</label
                              >
                              <input
                                type="password"
                                name="password"
                                id="login-password-inline"
                                required
                                class="form-control"
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="form__options form__options--forgot">
                              <a href="user_restore_pass.html">Forgot password ?</a>
                            </div>
                            <button type="submit" class="form__submit">
                              Sign in
                            </button>
                          </div>
                          <div class="form__remember">
                            <input
                              type="checkbox"
                              id="remember-in-inline"
                              class="in-checkbox"
                            />
                            <label for="remember-in-inline" class="in-label"
                              >Remember me</label
                            >
                          </div>
                          <div class="row">
                            <div class="form__options">
                              Not a user yet?<a href="user_register.html"
                                >Get an account</a
                              >
                            </div>
                          </div>
                        </form>
                        <!-- end of block .auth__form-->
                        <!-- END AUTH LOGIN-->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <!-- END CENTER SECTION-->
      <!-- BEGIN AFTER CENTER SECTION-->
      <!-- END AFTER CENTER SECTION-->

    `;
  }
}
export default new LoginView();
