class PageView {
  _data;

  render(type = 'beforeend', data, render = true) {
    // if (!data || (Array.isArray(data) && data.length === 0))
    //   return this.renderError();

    this._data = data;

    this._changePageStyle();

    const markup = this._generateMarkup();

    if (!render) return markup;

    console.log(this._pageElement);
    this._clear();

    this._pageElement.insertAdjacentHTML(type, markup);
  }

  _clear() {
    this._pageElement.innerHTML = '';
  }

  _changePageStyle() {
    if (this._page !== 'Home') {
      document.querySelector('body').className = '';
      document.querySelector('body').classList.toggle(this._page.toLowerCase());
      document.querySelector('body').classList.toggle('menu-default');
      document.querySelector('body').classList.toggle('hover-default');
      document.querySelector('body').classList.toggle('sidebar-left');
      document.querySelector('body').classList.toggle('lg');

      document
        .querySelector('.box .header')
        .classList.remove('header--overlay');
      document.querySelector('.box .header').classList.remove('header--dark');
      document.querySelector('.box .header').classList.toggle('header--brand');

      document.querySelector('#header-nav').classList.remove('navbar--overlay');
    }
  }

  renderElement() {}

  renderSpinner() {}
  renderError() {}
  renderMessage() {}
}
export default PageView;
