import Controller from '../core/Controller.js';
import BannerView from './views/BannerView.js';
// import PropertyView from './views/PropertyView.js';
// import FeaturesView from './views/FeaturesView.js';
// import AgentsView from './views/AgentsView.js';
// import BlogView from './views/BlogView.js';
// import AchievementView from './views/AchievementView.js';
// import TestimonialsView from './views/TestimonialsView.js';

class HomeController extends Controller {
  init() {
    // location.reload();
    console.log('Home controller init');
    BannerView.render();
    // PropertyView.render();
    // FeaturesView.render();
    // AgentsView.render();
    // BlogView.render();
    // AchievementView.render();
    // TestimonialsView.render();
  }
}

export default new HomeController();
