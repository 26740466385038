import Controller from '../core/Controller.js';
import LoginView from './views/LoginView.js';
// import PropertyView from './views/PropertyView.js';
// import FeaturesView from './views/FeaturesView.js';
// import AgentsView from './views/AgentsView.js';
// import BlogView from './views/BlogView.js';
// import AchievementView from './views/AchievementView.js';
// import TestimonialsView from './views/TestimonialsView.js';

class UserController extends Controller {
  init() {
    console.log('Users controller init');
    LoginView.render();
    // PropertyView.render();
    // FeaturesView.render();
    // AgentsView.render();
    // BlogView.render();
    // AchievementView.render();
    // TestimonialsView.render();
  }
}

export default new UserController();
