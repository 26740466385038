import PageView from '../../core/PageView.js';
// import icons from '../../../img/icons.svg'; // Parcel 2

class DashBoardView extends PageView {
  _page = 'Dashboard';
  _pageElement = document.querySelector('.site-wrap .content');

  // console.log(_parentElement);

  _generateMarkup() {
    return `
      <div class="center">
        <div class="container">
          <div class="row">
            <header class="site__header">
              <h1 class="site__title site__title--center">Dashboard</h1>
            </header>
            <!-- BEGIN LISTING-->
            <div class="site site--dashboard"></div>
            <!-- END LISTING-->
            <!-- BEGIN SIDEBAR-->
            <div class="sidebar sidebar--dashboard"></div>
            <!-- END SIDEBAR-->
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
    `;
  }
}
export default new DashBoardView();
