import Controller from '../core/Controller.js';
import DashBoardView from './views/DashBoardView.js';

class DashboardController extends Controller {
  init() {
    console.log('Dashboard controller init');
    DashBoardView.render();
    // SliderView.render();
  }
}

export default new DashboardController();
