import { ErrorComponent } from '../common/common.js';
import { routes } from '../config/config.js';
import * as constants from '../config/constants.js';

const parseLocation = () => location.hash.slice(1).toLowerCase() || '/';

const findComponentByPath = (path, routes) =>
  routes.find(r => r.path.match(new RegExp(`^\\${path}$`, 'gm'))) || undefined;

export const router = () => {
  // Get the current path
  const path = parseLocation();

  // Find the component based on the current path

  // If there's no matching route, get the "Error" component
  const { controller = ErrorComponent } =
    findComponentByPath(path, routes) || {};

  // Call the controller init
  controller.init();
};
